import posthog from 'posthog-js';
import * as Sentry from '@sentry/react';

posthog.init(import.meta.env.VITE_POSTHOG_PUBLIC_KEY, {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
  ui_host: 'https://us.posthog.com',
  opt_in_site_apps: true,
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true
    }
  }
});

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      posthog.sentryIntegration({
        organization: import.meta.env.VITE_SENTRY_ORG,
        projectId: import.meta.env.VITE_SENTRY_PROJECT_ID
      })
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
